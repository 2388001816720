const QUALITY_10 = 10;
const QUALITY_20 = 20;
const QUALITY_30 = 30;

/**
 * Transforms a Cloudinary image URL with the specified quality, crop, and format.
 *
 * @param imageUrl - The original Cloudinary image URL.
 * @param quality - The desired image quality (default: "auto:good").
 * @param format - The desired image format (default: "auto").
 * @returns The transformed Cloudinary image URL.
 */
const transformImageQuality = (
  imageUrl: string,
  quality: number | string = "auto:good",
  format: string = "auto"
): string => {
  if (!imageUrl || !imageUrl.includes("/upload/")) return imageUrl;

  const appliedTransformation = `/upload/q_${quality},f_${format}/`;

  // Default transformation to check for in the URL
  const defaultTransformation = "/upload/c_scale,q_auto:good,f_auto/";

  // Check for default transformation in the URL
  const existingTransformation = imageUrl.includes(defaultTransformation)
    ? defaultTransformation
    : "/upload/";

  // Replace the existing transformation with the new transformation
  return imageUrl.replace(existingTransformation, appliedTransformation);
};

export { QUALITY_10, QUALITY_20, QUALITY_30, transformImageQuality };
