// Packages
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage, injectIntl } from 'react-intl';
// Components
import ModalCloseIcon from "../modalCloseIcon";
// Helpers, Utils etc.
import {
  translatedName,
  translatedDescription,
} from "../../helpers/translations";
import { QUALITY_20, transformImageQuality } from "../../helpers/imageTransformation";

class ItemDetailsPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      hide: false,
      itemDetails: {},
    };
  }

  closeItemDetailsPopup = () => {
    setTimeout(() => {
      this.props.onClose();
    }, 500);
    this.setState({ show: false });
  };

  static getDerivedStateFromProps(
    nextProps: props
  ) {
   return { itemDetails: nextProps.itemDetails };
  }

  formatDescription = (description: string) => {
    let descriptionLines = description.split("\r\n");
    return (
      descriptionLines.map((word: string, index: any) => {
        return <div key={index}>{index ? <br /> : false}{word}</div>;
      })
    );
  }

  render() {
    const { itemDetails, show } = this.state;
    const { intl } = this.props;

    const itemDescription = translatedDescription(itemDetails, intl.locale)

    return (
      <Modal
        show={show}
        size={itemDetails.image_url ? "lg" : "sm"}
        dialogClassName={
          this.state.hide
            ? "custom curved invisible item-details-popup"
            : "custom curved item-details-popup"
        }
        onHide={this.closeItemDetailsPopup}
        centered
      >
        <ModalCloseIcon onClick={this.closeItemDetailsPopup} />
        <div className="item-details-popup d-flex justify-content-evenly">
          <div className="details-container">
            <div className="header">
              {this.props.localisationEnabled
                ? translatedName(itemDetails, intl.locale)
                : itemDetails.name}
            </div>
            <div className="description">
              {itemDescription ? (
                this.formatDescription(itemDescription)
              ) : (
                <span className="no-description">
                  <FormattedMessage
                    id="item_details.no_description"
                    defaultMessage="No description"
                  />
                </span>
              )}
            </div>
          </div>
          {itemDetails.image_url && (
            <div
              className="image-container flex-fill"
              style={{
                backgroundImage: `url(${transformImageQuality(
                  itemDetails.image_url,
                  QUALITY_20
                )})`,
              }}
            ></div>
          )}
        </div>
      </Modal>
    );
  }
}

export default injectIntl(ItemDetailsPopup);
