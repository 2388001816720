import React, { useEffect, useState } from "react";
import {
  translatedDescription,
  translatedName,
} from "../../helpers/translations";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { QUALITY_20, transformImageQuality } from "../../helpers/imageTransformation";

interface IMenuItemDetailsPopupProps {
  storeOpen: any;
  itemDetails: any;
  onClose: any;
  showItemDetailsPopup: any;
}

export default function ItemDetailsPopup(props: IMenuItemDetailsPopupProps) {
  const intl = useIntl();
  let itemDescription = translatedDescription(props.itemDetails, intl.locale);

  let formatDescription = (description: string) => {
    let descriptionLines = description.split("\r\n");
    return descriptionLines.map((word: string, index: any) => {
      return (
        <div key={index}>
          {index ? <br /> : false}
          {word}
        </div>
      );
    });
  };

  return (
    <>
      <Modal
        show={props.showItemDetailsPopup}
        onHide={() => props.onClose()}
        backdropClassName="item-details-backdrop"
      />
      <div
        className={
          props.showItemDetailsPopup
            ? "item-details-popup-mobile show"
            : "item-details-popup-mobile hide"
        }
      >
        {props.itemDetails.image_url && (
          <div
            className="image-container"
            style={{
              backgroundImage: `url(${transformImageQuality(
                props.itemDetails.image_url,
                QUALITY_20
              )})`,
            }}
          ></div>
        )}
        <div className="details-container">
          <span className="item-name">
            {translatedName(props.itemDetails, intl.locale)}
          </span>
          <span className="item-description">
            {itemDescription ? (
              formatDescription(itemDescription)
            ) : (
              <span className="no-description">
                <FormattedMessage
                  id="item_details.no_description"
                  defaultMessage="No description"
                />
              </span>
            )}
          </span>
          <span className="close-btn" onClick={() => props.onClose()}>
            Close
          </span>
        </div>
      </div>
    </>
  );
}
