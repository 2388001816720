// Packages
import React, { Component } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import MenuItemMobile from "../menuItemMobile";
import {
  translatedName,
  translatedDescription,
} from "../../helpers/translations";
import { QUALITY_20, transformImageQuality } from "../../helpers/imageTransformation";

interface IGroupedItemModalProps {
  onClose: any;
  groupedItem: any;
  checkAndRemoveAdvertisementClass: any;
}
interface IGroupedItemModalState {}

class GroupedItemModal extends Component<
  IGroupedItemModalProps,
  IGroupedItemModalState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  closeGroupedItemMenu = () => {
    this.props.onClose();
  };

  render() {
    const { groupedItem, intl } = this.props;

    return (
      <div className="grouped-item-modal">
        {groupedItem.image_url ? (
          <div
            className="item-img"
            style={{
              backgroundImage: `url(${transformImageQuality(
                groupedItem.image_url,
                QUALITY_20
              )})`,
            }}
          ></div>
        ) : (
          false
        )}
        <div className="closeModifier">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={this.props.onClose}
            size="2x"
            className="close-icon"
          />
        </div>
        <div className="grouped-item-details">
          <p className="item-name">
            {translatedName(groupedItem, intl.locale)}
          </p>
          <p className="item-description">
            {translatedDescription(groupedItem, intl.locale)}
          </p>
        </div>
        <div className="group-items">
          {groupedItem.items.map((item: any, index: any) => {
            return (
              <MenuItemMobile
                key={index}
                closeGroupedItemMenu={() => this.closeGroupedItemMenu()}
                isGroupedItem={true}
                parentItem={groupedItem}
                menuItem={item}
                checkAndRemoveAdvertisementClass={
                  this.props.checkAndRemoveAdvertisementClass
                }
              />
            );
          })}
        </div>
      </div>
    );
  }
}

export default injectIntl(GroupedItemModal);
