// Packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from 'react-intl';
// Components
import Button from "../button";
import ModalCloseIcon from "../modalCloseIcon";
import CustomisationPopup from "../customisationPopup";
import GroupedItemPopup from "../groupedItemPopup";
import ItemDetailsPopup from "../itemDetailsPopup";
import RepeatItemPopup from "../repeatItemPopup";
import AddedToCartPopup from "../addedToCartPopup";
// Icons, Images etc.
import NoImageAvailableIcon from "../../images/NoImageAvailableIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { errorOperations } from "../../state/features/error";
import { cartOperations } from "../../state/features/cart";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  addItemToCart,
  addModifiedItemToCart,
  updateItemWithCartIdInCart,
  updateItemWithIdInCart,
} from "../../helpers/cartFunctions";
import {
  translatedName,
  translatedDescription,
} from "../../helpers/translations";
import { isItemOutOfStock } from "../../helpers/utils";
import InfoIcon from "../../images/infoIcon";
import { QUALITY_20, transformImageQuality } from "../../helpers/imageTransformation";

interface IMenuItemProps {
  item?: any;
  currency?: any;
  storeOpen?: any;
  isGroupedItem?: boolean;
  parentItem?: any;
  cart?: any;
  isTaxInclusive?: boolean;
  orderType?: string;
  setCart?: void;
  hideGroupedItemMenu?: any;
  closeGroupedItemMenu?: any;
  updateError: any;
  checkAndRemoveAdvertisementClass?: any;
  outOfStockItems: any;
}

interface IMenuItemState {
  item: any;
  showCustomisationPopup: boolean;
  showGroupedItemPopup: boolean;
  showItemDetailsPopup: boolean;
  showRepeatItemPopup: boolean;
  repeatItem: any;
  cannotBeDecreased: boolean;
  showGroupItemRepeatPopup: boolean;
  showItemAddedPopup: boolean;
  showReadMore: boolean;
  isOutOfStockItem: boolean;
}

class MenuItem extends Component<IMenuItemProps, IMenuItemState> {
  constructor(props: IMenuItemProps) {
    super(props);
    this.state = {
      item: {} as any,
      showCustomisationPopup: false,
      showGroupedItemPopup: false,
      showItemDetailsPopup: false,
      showRepeatItemPopup: false,
      repeatItem: {},
      cannotBeDecreased: false,
      showGroupItemRepeatPopup: false,
      showItemAddedPopup: false,
      showReadMore: false,
      isOutOfStockItem: false
    };
  }

  static getDerivedStateFromProps(nextProps: any, nextState: any) {
    if (nextProps.item != nextState.item) {
      return { item: nextProps.item };
    }
    return null;
  }

  addToCartAnimation = (action: string) => {
    if (action == "add") {
      document.getElementById("header-cart-button").classList.add("itemAdded");
      setTimeout(function () {
        document
          .getElementById("header-cart-button")
          .classList.remove("itemAdded");
      }, 1000);
    } else {
      document
        .getElementById("header-cart-button")
        .classList.add("itemRemoved");
      setTimeout(function () {
        document
          .getElementById("header-cart-button")
          .classList.remove("itemRemoved");
      }, 1000);
    }
  };

  componentDidMount() {
    this.checkForLongDescription();
    if (this.props.outOfStockItems && this.props.outOfStockItems.length > 0) {
      this.setState({
        isOutOfStockItem: isItemOutOfStock(
          this.props.item,
          this.props.outOfStockItems
        ),
      });
    }
  }

  componentDidUpdate(prevState: any) {
    if (prevState.item !== this.state.item) {
      this.checkForMultipleCustomisations();
      this.checkForLongDescription();
      if (this.props.outOfStockItems && this.props.outOfStockItems.length > 0) {
        this.setState({
          isOutOfStockItem: isItemOutOfStock(
            this.props.item,
            this.props.outOfStockItems
          ),
        });
      }
    }
  }

  addItem = (e: React.MouseEvent<Element, MouseEvent>, item: any) => {
    if (this.props.isGroupedItem) {
      if (this.props.cart.items.length) {
        let cartItem = this.props.cart.items.find((e: any) => {
          return e.id === item.id;
        });
        if (cartItem) {
          item.count = cartItem.count + 1;
          updateItemWithIdInCart(
            item,
            this.props.cart.items,
            this.props.setCart
          );
        } else {
          item.count = 1;
          addItemToCart(item, this.props.cart.items, this.props.setCart);
        }
      } else {
        item.count = 1;
        addItemToCart(item, this.props.cart.items, this.props.setCart);
      }
    } else {
      item.count = 1;
      addItemToCart(item, this.props.cart.items, this.props.setCart);
    }
    this.addToCartAnimation("add");
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
      this.setState({ showGroupItemRepeatPopup: false });
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  incrementCount = (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
    item.count = item.count + 1;
    updateItemWithIdInCart(item, this.props.cart.items, this.props.setCart);
    this.addToCartAnimation("add");
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  decrementCount = (e: React.MouseEvent<HTMLButtonElement>, item: any) => {
    item.count = item.count - 1;
    updateItemWithIdInCart(item, this.props.cart.items, this.props.setCart);
    this.addToCartAnimation("remove");
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  addModifiedItem(e: React.MouseEvent<HTMLButtonElement>, item: any) {
    addModifiedItemToCart(item, this.props.cart.items, this.props.setCart);
    this.setState({
      showCustomisationPopup: false,
      showRepeatItemPopup: false,
    });
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, true
      );
      this.props.closeGroupedItemMenu();
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  }

  repeatPreviousItem = (item: any, parentItemId = null) => {
    item.count = item.count + 1;
    updateItemWithCartIdInCart(item, this.props.cart.items, this.props.setCart);
    this.setState({
      showRepeatItemPopup: false,
      showGroupItemRepeatPopup: false,
    });
    this.addToCartAnimation("add");
    if (parentItemId) {
      this.checkAndRemoveAdvertisementClass(parentItemId, true);
    } else {
      this.checkAndRemoveAdvertisementClass(item.id, true);
    }
  };

  onCustomisationRepeat = (
    e: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    if (this.props.isGroupedItem) {
      this.props.hideGroupedItemMenu(false);
    }
    let repeatItem = new Object();
    let i = this.props.cart.items.length - 1;
    for (i; i >= 0; i--) {
      if (item.id === this.props.cart.items[i].id) {
        this.setState({ repeatItem: this.props.cart.items[i] });
        break;
      }
    }
    this.setState({ showRepeatItemPopup: true });
  };

  onGroupedItemRepeat(e: React.MouseEvent<HTMLButtonElement>, item: any) {
    let repeatItem: any = new Object();
    let i = this.props.cart.items.length - 1;
    for (i; i >= 0; i--) {
      for (let j = 0; j < item.items.length; j++) {
        if (item.items[j].id === this.props.cart.items[i].id) {
          repeatItem = this.props.cart.items[i];
        }
      }
      if (repeatItem.id) {
        this.setState({ repeatItem: this.props.cart.items[i] });
        break;
      }
    }
    this.setState({ showGroupItemRepeatPopup: true });
  }

  checkForMultipleCustomisations = () => {
    let customisationsCount = this.props.cart.items.reduce(
      (count: number, item: any) => {
        if (item.id == this.state.item.id) {
          return count + 1;
        }
        return count;
      },
      0
    );
    if (
      customisationsCount > 1 ||
      (this.state.item.items && this.state.item.items.length)
    ) {
      this.setState({ cannotBeDecreased: true });
    }
  };

  checkForLongDescription = () => {
    let item = this.state.item;
    if (item.short_description || item.description) {
      setTimeout(() => {
        var itemDescriptionElem = document.getElementById("description_"+item.id);
        if (itemDescriptionElem?.scrollHeight > itemDescriptionElem?.clientHeight) {
          this.setState({ showReadMore: true });
        }
      }, 100);
    }
  }

  openCustomisationMenu = (
    e: React.MouseEvent<Element, MouseEvent>,
    item: any
  ) => {
    if (this.props.isGroupedItem) {
      this.props.hideGroupedItemMenu(false);
      this.setState({ showCustomisationPopup: true });
    } else {
      this.setState({ showCustomisationPopup: true });
    }
  };

  closeCustomisationPopup = () => {
    this.setState({
      showCustomisationPopup: false,
      showRepeatItemPopup: false,
    });
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, false
      );
      this.props.hideGroupedItemMenu(true);
    } else {
      this.checkAndRemoveAdvertisementClass(this.state.item.id, false);
    }
  };

  closeRepeatItemPopup = () => {
    this.setState({ showRepeatItemPopup: false });
    if (this.props.isGroupedItem) {
      this.props.checkAndRemoveAdvertisementClass(
        this.props.parentItem.id, false
      );
      this.props.hideGroupedItemMenu(true);
    } else {
      this.checkAndRemoveAdvertisementClass(this.state.item.id, false);
    }
  };

  closeGroupItemRepeatPopup = () => {
    this.setState({ showGroupItemRepeatPopup: false });
    this.checkAndRemoveAdvertisementClass(this.state.item.id, false);
  };

  openGroupedItemMenu(e: React.MouseEvent<Element, MouseEvent>, item: any) {
    this.setState({ showGroupedItemPopup: true });
  }

  closeGroupedItemPopup() {
    this.setState({
      showGroupedItemPopup: false,
      showGroupItemRepeatPopup: false,
    });
    this.checkAndRemoveAdvertisementClass(this.state.item.id, false);
  }

  toggleItemDetailsPopup(status: boolean) {
    this.setState({ showItemDetailsPopup: status });
  }

  showMultipleCustomisationError() {
    this.props.updateError({
      message:
        <FormattedMessage
          id="menu.item.multiple_customizations_added"
          defaultMessage="This item has multiple customizations added. Remove the correct item from the cart."
        />
    });
  }

  checkAndRemoveAdvertisementClass(item_id: any, showNotification: boolean) {
    let itemElem =
      document.getElementsByClassName(`add-to-cart-${item_id}`)[0] ||
      document.getElementsByClassName(`increment-item-${item_id}`)[0];
    if (itemElem.classList.contains("added-through-advertisement")) {
      itemElem.classList.remove("added-through-advertisement");
      if (showNotification) {
        this.toggleItemAddedPopup(true);
      }
    }
  }

  toggleItemAddedPopup(showItemAddedPopup: boolean) {
    this.setState({ showItemAddedPopup });
  }

  render() {
    const { item, showReadMore, isOutOfStockItem } = this.state;
    const { intl } = this.props;

    var itemDescription = item.short_description || item.description || "";

    return (
      <div className={`${isOutOfStockItem ? "menu-item-disabled" : ""} menu-item d-flex my-3`}>
        {item.image_url || translatedDescription(item, intl.locale) ? (
          <span
            className="info-icon"
            onClick={() => this.toggleItemDetailsPopup(true)}
          >
            <InfoIcon />
          </span>
        ) : null}
        {item.image_url && item.image_url.length > 0 ? (
          <div className="image-container" id={"image_"+item.id}>
            <div
              className="item-img"
              style={{
                backgroundImage: `url(${
                  item.image_url
                    ? transformImageQuality(item.image_url, QUALITY_20)
                    : NoImageAvailableIcon
                })`,
              }}
              onClick={() => this.toggleItemDetailsPopup(true)}
            />
          </div>
        ) : (
          false
        )}

        <div
          className={
            item.image_url && item.image_url.length > 0
              ? "item-details ml-3 mr-3 my-4 position-relative with-thumb"
              : "item-details ml-3 mr-3 my-4 position-relative no-thumb"
          }
        >
          <p
            className="item-name"
            id={"item_"+item.id}
            title={translatedName(item, intl.locale)}
          >
            {translatedName(item, intl.locale)}
          </p>
          {itemDescription ? (
            <div className="descriptionContainer">
              <FormattedMessage
                id="menu.item.click_to_read_more"
                defaultMessage="Click to Read More"
              >
                {(title) => (
                  <div
                    className="item-description"
                    id={"description_"+item.id}
                    title={title}
                    onClick={() => this.toggleItemDetailsPopup(true)}
                  >
                    {translatedDescription(item, intl.locale)}
                  </div>
                )}
              </FormattedMessage>
              {showReadMore ? (
                <strong className="readmore"  onClick={() => this.toggleItemDetailsPopup(true)}>
                  <FormattedMessage
                    id="menu.item.read_more"
                    defaultMessage="Read More"
                  />
                </strong>
              ) : false}
            </div>
          ) : <div className="descriptionContainer"></div>}
          <p className="item-price my-2">
            {item.unit_price
              ? this.props.currency + " " + formatDecimal(item.unit_price)
              : null}
          </p>
          <div className="item-actions">
            {!item.count || !this.props.storeOpen || this.props.isGroupedItem || isOutOfStockItem ? (
              <Button
              disable={!this.props.storeOpen || isOutOfStockItem}
              text={
                isOutOfStockItem ? (
                  <span>
                    <FormattedMessage
                      id="menu.out_of_stock"
                      defaultMessage="Out of Stock"
                    />
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon className="mr-2" icon={faPlusCircle} />
                    <FormattedMessage
                      id="menu.item.add_to_order"
                      defaultMessage="Add to Order"
                    />
                  </span>
                )
              }
              className={`btn-sm px-3 py-2 btn-primary add-to-cart-${item.id}`}
              onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                item.items && item.items.length
                  ? this.openGroupedItemMenu(e, item)
                  : item.groups.length >= 1
                  ? this.openCustomisationMenu(e, item)
                  : this.addItem(e, item);
              }}
            />
            ) : (
              <div className="count-controller">
                <button
                  className={
                    `btn btn-primary btn-sm px-3 py-2 primary decrement-item-${item.id}`
                  }
                  onClick={(e) => {
                    this.state.cannotBeDecreased
                      ? this.showMultipleCustomisationError()
                      : this.decrementCount(e, item);
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </button>
                <input
                  readOnly
                  type="number"
                  className="count-input py-3"
                  value={item.count}
                ></input>
                <button
                  onClick={(e) => {
                    item.items && item.items.length
                      ? this.onGroupedItemRepeat(e, item)
                      : item.groups.length >= 1
                      ? this.onCustomisationRepeat(e, item)
                      : this.incrementCount(e, item);
                  }}
                  className={
                    `btn btn-primary btn-sm px-3 py-2 primary increment-item-${item.id}`
                  }
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            )}
          </div>
        </div>
        {this.state.showCustomisationPopup && (
          <CustomisationPopup
            onClose={() => this.closeCustomisationPopup()}
            modifierItem={item}
            onModifierAdd={(
              e: React.MouseEvent<HTMLButtonElement>,
              item: any
            ) => this.addModifiedItem(e, item)}
          />
        )}
        {this.state.showGroupedItemPopup ? (
          <GroupedItemPopup
            groupedItem={item}
            onClose={() => this.closeGroupedItemPopup()}
            checkAndRemoveAdvertisementClass={(
              item_id: any, showNotification: boolean
            ) => {
              this.checkAndRemoveAdvertisementClass(item_id, showNotification)
            }}
          />
        ) : null}
        {this.state.showRepeatItemPopup && (
          <RepeatItemPopup
            onClose={() => this.closeRepeatItemPopup()}
            onAddNew={(e: React.MouseEvent<HTMLButtonElement>) =>
              this.openCustomisationMenu(e, item)
            }
            onRepeat={() => this.repeatPreviousItem(this.state.repeatItem)}
            repeatItem={this.state.repeatItem}
            currency={this.props.currency}
          />
        )}
        {this.state.showItemDetailsPopup && (
          <ItemDetailsPopup
            storeOpen={this.props.storeOpen}
            itemDetails={item}
            onClose={() => this.toggleItemDetailsPopup(false)}
          />
        )}
        {this.state.showGroupItemRepeatPopup && (
          <RepeatItemPopup
            onClose={() => this.closeGroupItemRepeatPopup()}
            onAddNew={(e: React.MouseEvent<HTMLButtonElement>) =>
              this.openGroupedItemMenu(e, item)
            }
            onRepeat={() => {
              this.repeatPreviousItem(this.state.repeatItem, this.state.item.id)
            }}
            repeatItem={this.state.repeatItem}
            currency={this.props.currency}
          />
        )}
        {this.state.showItemAddedPopup && (
          <AddedToCartPopup onClose={() => this.toggleItemAddedPopup(false)} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;
  let storeOpen = state.storeTimings.open;
  let cart = state.cart;
  let isTaxInclusive = state.menu.is_tax_inclusive || false;
  let orderType = state.session.order_type;
  let outOfStockItems = state.menu.out_of_stock_items

  return {
    currency,
    storeOpen,
    cart,
    orderType,
    outOfStockItems
  };
};

const mapDispatchToProps = {
  updateError: errorOperations.updateError,
  setCart: cartOperations.setCart,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(MenuItem));
