// Packages
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { useIntl, FormattedMessage } from 'react-intl';
import { Dropdown } from "react-bootstrap";
import "bootstrap/js/dist/dropdown.js";
// Icons, Images etc.
import NoImageAvailableIcon from "../../images/NoImageAvailableIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
// Redux Operations
import { invoiceOperations } from "../../state/features/invoice";
// Helpers, Utils etc.
import { formatDecimal } from "../../helpers/itemCalculations";
import {
  translatedName,
} from "../../helpers/translations";
import { QUALITY_10, transformImageQuality } from "../../helpers/imageTransformation";

interface IPastOrderDetailsPopupProps {
  pastOrder: any;
  onClose: any;
  currency: any;
  reorderhandler: any;
  onSendInvoiceEmailClick: any;
}

function PastOrderDetailsPopup(props: IPastOrderDetailsPopupProps) {
  const [show, setShow] = useState(true);

  const intl = useIntl();

  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      props.onClose();
    }, 500);
  };

  const dateFromString = (dateString: string) => {
    let date = new Date(dateString);

    return date.toLocaleDateString(undefined, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  return (
    <Modal
      show={show}
      dialogClassName="custom curved"
      size="lg"
      onHide={handleClose}
      centered
    >
      <div className="past-order-details">
        <h1 className="order-number">{props.pastOrder.order_number}</h1>
        <div className="order-info">
          <p className="order-type">
            {props.pastOrder.order_details.order_type == "pickup" ? (
              <FormattedMessage
                id="my_account.take_away"
                defaultMessage="Take-away"
              />
            ) : (
              <FormattedMessage
                id="global.delivery"
                defaultMessage="Delivery"
              />
            )}
          </p>|
          <p className="order-date">
            {dateFromString(props.pastOrder.created_at)}
          </p>
        </div>
        <table className="order-details-table">
          <thead>
            <tr>
              <th className="name">
                <FormattedMessage
                  id="global.item"
                  defaultMessage="Item"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.quantity"
                  defaultMessage="Quantity"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.price"
                  defaultMessage="Price"
                />
              </th>
              <th>
                <FormattedMessage
                  id="global.total"
                  defaultMessage="Total"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {props.pastOrder.order_details.items.map(
              (item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td
                      className="name"
                      style={
                        item.modifiers.length
                          ? { verticalAlign: "top" }
                          : { verticalAlign: "center" }
                      }
                    >
                      <div className="d-flex align-items-center">
                        <div
                          className="item-image"
                          style={{
                            backgroundImage: `url(${
                              item.image_url
                                ? transformImageQuality(item.image_url, QUALITY_10)
                                : NoImageAvailableIcon
                            })`,
                          }}
                        />
                        <div>
                          <p>
                            {translatedName(item, intl.locale)}
                          </p>
                          {item.modifiers.length ? (
                            <div className="modifiers">
                              {item.modifiers.map((modifier: any) => {
                                return (
                                  <p className="modifier-item" key={modifier.id}>
                                    {translatedName(modifier, intl.locale)}
                                    {modifier.unit_price > 0 ? (
                                      <span className="price">
                                        +{props.currency}{" "}
                                        {formatDecimal(modifier.unit_price)}
                                      </span>
                                    ) : " "}
                                    {"x " + modifier.quantity}
                                  </p>
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </td>
                    <td
                      style={
                        item.modifiers.length
                          ? { verticalAlign: "top" }
                          : { verticalAlign: "center" }
                      }
                    >
                      {item.quantity}
                    </td>
                    <td
                      style={
                        item.modifiers.length
                          ? { verticalAlign: "top" }
                          : { verticalAlign: "center" }
                      }
                    >
                      {item.unit_price}
                    </td>
                    <td
                      style={
                        item.modifiers.length
                          ? { verticalAlign: "top" }
                          : { verticalAlign: "center" }
                      }
                    >
                      {item.sub_total}
                    </td>
                  </tr>
                );
              }
            )}

          </tbody>
          <tfoot>
            <tr className="amount subtotal">
              <td className="label text-right" colSpan={3}>
                <FormattedMessage
                  id="global.subtotal"
                  defaultMessage="Subtotal"
                />
              </td>
              <td className="value text-right pr-3">{formatDecimal(props.pastOrder.order_details.sub_total)}</td>
            </tr>

            {props.pastOrder.order_details.surcharges_breakdown &&
              props.pastOrder.order_details.surcharges_breakdown.length ? (
                props.pastOrder.order_details.surcharges_breakdown.map((surcharge: any) => {
                  return (
                    <tr className="amount charges" key={surcharge.name}>
                      <td className="label text-right" colSpan={3}>{surcharge.name}</td>
                      <td className="value text-right pr-3">{formatDecimal(surcharge.amount)}</td>
                    </tr>
                  );
                })
              ) : false}

            {props.pastOrder.order_details.discounts.length ? (
              <tr className="amount discounts">
                <td className="label text-right" colSpan={3}>{props.pastOrder.order_details.discounts[0].name}</td>
                <td className="value text-right pr-3">-{formatDecimal(props.pastOrder.order_details.discounts[0].amount)}</td>
              </tr>
            ) : (
              false
            )}

            {props.pastOrder.order_details.redeemed_loyalty ? (
              <tr className="amount discounts">
                <td className="label text-right" colSpan={3}>
                  <FormattedMessage
                    id="global.loyalty"
                    defaultMessage="Loyalty"
                  />
                </td>
                <td className="value text-right pr-3">-{formatDecimal(props.pastOrder.order_details.redeemed_loyalty)}</td>
              </tr>
            ) : (
              false
            )}
            {!props.pastOrder.order_details.is_tax_inclusive &&
              props.pastOrder.order_details.tax_total > 0 ? (
              <tr className="amount tax">
                <td className="label text-right" colSpan={3}>
                  <FormattedMessage
                    id="global.tax"
                    defaultMessage="Tax"
                  />
                </td>
                <td className="value text-right pr-3">{formatDecimal(props.pastOrder.order_details.tax_total)}</td>
              </tr>
            ) : (
              false
            )}
            <tr className="amount total">
              <td className="label text-right" colSpan={3}>
                <FormattedMessage
                  id="global.total"
                  defaultMessage="Total"
                />
              </td>
              <td className="value text-right pr-3">{props.pastOrder.order_details.total_amount}</td>
            </tr>
          </tfoot>
        </table>
        <div className="d-flex justify-content-end">
          <button
            onClick={handleClose}
            className="button px-4 btn  btn-outline-primary mr-3"
          >
            <FormattedMessage
              id="global.close"
              defaultMessage="Close"
            />
          </button>

          <div className="dropdown">
            <button
              className="button px-4 btn btn-outline-primary mr-3"
              type="button"
              id="invoiceDropDownButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <FormattedMessage
                id="my_account.invoice"
                defaultMessage="Invoice"
              />
              <FontAwesomeIcon className="ml-1" size="1x" icon={faCaretDown} />
            </button>
            <div className="dropdown-menu invoice-dropdown" aria-labelledby="dropdownMenuButton">
              <Link to={`/invoices/${props.pastOrder.order_number}.pdf`} target="_blank" className="dropdown-item invoice-dropdown-item">
                <FormattedMessage
                  id="my_account.invoice.print"
                  defaultMessage="Print Invoice"
                />
              </Link>
              <Link to={`/invoices/${props.pastOrder.order_number}.pdf?download=true`} target="_blank" className="dropdown-item invoice-dropdown-item">
                <FormattedMessage
                  id="my_account.invoice.download"
                  defaultMessage="Download Invoice"
                />
              </Link>
              <button
                className="dropdown-item invoice-dropdown-item"
                onClick={props.onSendInvoiceEmailClick}
              >
                <FormattedMessage
                  id="my_account.invoice.send_via_email"
                  defaultMessage="Send Invoice via Email"
                />
              </button>
            </div>
          </div>

          <button
            onClick={() => props.reorderhandler(props.pastOrder)}
            className="button px-4 btn btn-primary"
          >
            <FormattedMessage
              id="my_account.re_order"
              defaultMessage="Re-Order"
            />
          </button>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state: any) => {
  let currency = state.company.currency;

  return {
    currency,
  };
};

export default connect(mapStateToProps)(PastOrderDetailsPopup);
